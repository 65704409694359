import React from "react";
import IconArrowRight from "../../assets/icons/arrow-right.svg";
import styled, { css } from "styled-components";

const Button = styled.button`
  border: none;
  background: transparent;
  border-top: solid 1px black;
  padding: 8px;
  width: ${props => (props.fluid ? "100%" : "auto")};
  outline: none;
  text-align: left;

  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;

  &:hover {
    padding-left: 16px;
    font-weight: 700;
    background: var(--accent);
    text-decoration: none;
  }

  &:last-child {
    border-bottom: solid 1px black;
  }
`;

function Arrowed({ children, arrowOnHoverOnly = false, ...props }) {
  return (
    <Button
      css={`
        display: flex;
        align-items: center;

        svg {
          margin-left: auto;
        }

        ${arrowOnHoverOnly
          ? css`
              svg {
                opacity: 0;
              }

              &:hover {
                svg {
                  opacity: 1;
                }
              }
            `
          : ""}
      `}
      {...props}
    >
      {children}
      <IconArrowRight />
    </Button>
  );
}

Button.Arrowed = Arrowed;

export default Button;
