import { graphql } from "gatsby";
import React, { useState } from "react";
import Layout from "../components/common/Layout";
import Section from "../components/common/Section";
import EventCard from "../components/common/EventCard";
import Button from "../components/common/Button";
import { H1, P } from "../components/common/Typography";
import { css } from "styled-components";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import { MEDIA_QUERIES } from "../config";
import SEO from "../components/common/SEO";
// import { StaticImage } from "gatsby-plugin-image";
import imageHero from "../assets/images/hero-events.jpg";

const FILTER_TYPES = ["Όλα", "Παραστάσεις Σχολής", "Σεμινάρια", "Εκδηλώσεις"];

const TITLE = "Παραστάσεις - Σεμινάρια";

function EventsPage({ data }) {
  const [filter, setFilter] = useState(FILTER_TYPES[0]);
  const events = data.allContentfulEvent.nodes;
  const filteredEvents =
    filter === FILTER_TYPES[0] ? events : events.filter(x => x.type === filter);

  return (
    <Layout>
      <SEO title={TITLE} />

      <main>
        {/* <StaticImage
          alt="Events"
          src="../assets/images/hero-events.jpg"
          placeholder="blurred"
          layout="fullWidth"
        /> */}
        <img
          css={`
            width: 100%;
            vertical-align: bottom;
            max-height: 476px;
            object-fit: cover;
          `}
          src={imageHero}
          alt="Events"
        />
        <Section
          css={`
            padding: 72px 0;
            text-align: center;

            ${H1} {
              margin-bottom: 32px;
            }

            ${P} {
              margin-left: auto;
              margin-right: auto;
              max-width: 587px;
            }

            ${MEDIA_QUERIES.mobile} {
              padding: 32px 16px;
            }
          `}
        >
          <H1>{TITLE}</H1>
          <P>
            Με πολλή χαρά και ενθουσιασμό διοργανώνουμε σε κεντρικά θέατρα της
            Αθήνας τις παραστάσεις της σχολής μας! Πρωταγωνιστές της βραδιάς
            είναι οι μαθητές μας. Αποδέκτες οι γονείς και το κοινό που τις
            παρακολουθεί.
          </P>
          <P>
            Επίσης, διοργανώνουμε σεμινάρια χορού υψηλής καλλιτεχνικής αξίας,
            προσκαλώντας γνωστούς χορογράφους από την Ελλάδα και το εξωτερικό,
            στοχεύοντας το επίπεδο των μαθητών μας να εξελίσσεται διαρκώς
            ακολουθώντας τις νέες τάσεις της παγκόσμιας χορευτικής σκηνής.
          </P>
        </Section>
        <Section
          css={`
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 24px;
            margin-bottom: 80px;

            ${MEDIA_QUERIES.mobile} {
              grid-template-columns: 1fr;
              padding: 0 16px;
            }
          `}
        >
          {FILTER_TYPES.map(type => (
            <Button
              key={type}
              fluid
              onClick={() => setFilter(type)}
              css={`
                text-align: center;
                border-bottom: 1px solid #000;

                ${filter === type
                  ? css`
                      font-weight: 700;
                      background: var(--accent);
                    `
                  : ""}

                &:hover {
                  padding-left: 8px;
                }
              `}
            >
              {type}
            </Button>
          ))}
        </Section>
        <SimpleReactLightbox>
          <SRLWrapper>
            <Section
              css={`
                display: grid;
                grid-template-columns: repeat(auto-fit, 326px);
                gap: 80px 23px;
                margin-bottom: 128px;

                ${MEDIA_QUERIES.mobile} {
                  justify-items: center;
                  grid-template-columns: 1fr;
                }
              `}
            >
              {filteredEvents.map(event => (
                <EventCard key={event.name} event={event} />
              ))}
            </Section>
          </SRLWrapper>
        </SimpleReactLightbox>
      </main>
    </Layout>
  );
}

export const pageQuery = graphql`
  query AllEvents {
    allContentfulEvent(sort: { fields: date, order: DESC }) {
      nodes {
        contentful_id
        date(formatString: "DD/MM/YY")
        image {
          fixed(width: 652) {
            width
            src
          }
        }
        name
        type
        program {
          file {
            url
          }
        }
      }
    }
  }
`;

export default EventsPage;
